import {
  Container,
  Stack,
  Button,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import useLocales from '#/lib/hooks/useLocales';
import IntroHead from '#/components/shared/IntroHead';
import Iconify from '#/components/shared/ui/Iconify';
import GridView from './grid/grid-view';
import Filters from './filters';
import { IFile, IFileFilters, IFileFilterValue } from '#/types/file';
import useTable from '#/lib/hooks/useTable';
import TableView from './table/table-view';
import useBoolean from '#/lib/hooks/useBoolean';
import { isAfter } from '#/utils/formatTime';

const defaultFilters: IFileFilters = {
  name: '',
  type: [],
  startDate: null,
  endDate: null,
};

export const FILE_TYPE_OPTIONS = [
  'folder',
  'txt',
  'zip',
  'audio',
  'image',
  'video',
  'word',
  'excel',
  'powerpoint',
  'pdf',
  'photoshop',
  'illustrator',
];

export default function DFPhotosVideos() {
  const [filters, setFilters] = useState(defaultFilters);
  const [view, setView] = useState<string>('photos');
  const openDateRange = useBoolean();

  const { translate } = useLocales();
  const navigate = useNavigate();
  const table = useTable({ defaultRowsPerPage: 10 });

  const dataFiltered: IFile[] = [
    {
      id: '1',
      name: 'Test',
      size: 12000,
      type: 'folder',
      url: 'https://google.com',
      tags: [],
      totalFiles: 4,
      isFavorited: false,
      shared: null,
      createdAt: '2021-09-21T00:00:00Z',
      modifiedAt: '2021-09-21T00:00:00Z',
    },
  ];

  const notFound = !dataFiltered.length;

  const dateError = isAfter(filters.startDate, filters.endDate);

  const handleFilters = useCallback(
    (name: string, value: IFileFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleBackToDashboard = () => {
    navigate('/dashboard/digitaler-nachlass');
  };

  const handleChangeView = useCallback(
    (event: React.MouseEvent<HTMLElement>, newView: string | null) => {
      if (newView !== null) {
        setView(newView);
      }
    },
    []
  );

  return (
    <Container>
      <Stack direction="row" justifyContent="center">
        <Button onClick={handleBackToDashboard} sx={{ width: 'fit-content' }}>
          <Typography>{String(translate('global.back'))}</Typography>
        </Button>
      </Stack>
      <IntroHead
        title={String(translate('dfPhotosVideos.introHead'))}
        description={String(translate('dfPhotosVideos.introDescription'))}
      />
      <Stack
        spacing={2.5}
        sx={{
          my: { xs: 3, md: 5 },
        }}
      >
        <Stack
          spacing={2}
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'flex-end', md: 'center' }}
        >
          <Filters
            openDateRange={openDateRange.value}
            onCloseDateRange={openDateRange.onFalse}
            onOpenDateRange={openDateRange.onTrue}
            filters={filters}
            onFilters={handleFilters}
            typeOptions={FILE_TYPE_OPTIONS}
            dateError={dateError}
          />
          <ToggleButtonGroup
            size="small"
            value={view}
            exclusive
            onChange={handleChangeView}
          >
            <ToggleButton value="list">
              <Iconify icon="solar:list-bold" />
            </ToggleButton>

            <ToggleButton value="grid">
              <Iconify icon="mingcute:dot-grid-fill" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Stack>

      {view === 'list' ? (
        <TableView
          table={table}
          dataFiltered={dataFiltered}
          notFound={notFound}
          onDeleteRow={() => console.info('DELETE ROW')}
          onOpenConfirm={() => console.info('OPEN CONFIRM')}
        />
      ) : (
        <GridView
          table={table}
          dataFiltered={dataFiltered}
          onDeleteItem={() => console.info('DELETE ITEM')}
          onOpenConfirm={() => console.info('OPEN CONfirm')}
        />
      )}
    </Container>
  );
}
