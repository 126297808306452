import { Box, CardProps, ListItemText, Paper, Stack } from '@mui/material';
import { IFolderManager } from '#/types/file';
import { fData } from '#/utils/formatNumber';

interface Props extends CardProps {
  folder: IFolderManager;
  selected?: boolean;
  onSelect?: VoidFunction;
  onDelete: VoidFunction;
}

export default function GridFolderItem({
  folder,
  sx,
  onDelete,
  ...other
}: Props) {
  const renderIcon = (
    <Box
      component="img"
      src="/assets/icons/ic_folder.svg"
      alt="folder"
      sx={{ width: 36, height: 36 }}
    />
  );

  const renderText = (
    <ListItemText
      primary={folder.name}
      secondary={
        <>
          {fData(folder.size)}
          <Box
            component="span"
            sx={{
              mx: 0.75,
              width: 2,
              height: 2,
              borderRadius: '50%',
              bgcolor: 'currentColor',
            }}
          />
          {folder.totalFiles} files
        </>
      }
      primaryTypographyProps={{
        noWrap: true,
        typography: 'subtitle1',
      }}
      secondaryTypographyProps={{
        mt: 0.5,
        component: 'span',
        alignItems: 'center',
        typography: 'caption',
        color: 'text.disabled',
        display: 'inline-flex',
      }}
    />
  );

  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={1}
      alignItems="flex-start"
      sx={{
        p: 2.5,
        maxWidth: 222,
        borderRadius: 2,
        bgcolor: 'unset',
        cursor: 'pointer',
        position: 'relative',
        ...sx,
      }}
      {...other}
    >
      <Box>{renderIcon}</Box>
      {renderText}
    </Stack>
  );
}
