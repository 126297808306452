import {
  alpha,
  Checkbox,
  ListItemText,
  Stack,
  TableCell,
  tableCellClasses,
  TableRow,
  tableRowClasses,
  Typography,
  useTheme,
} from '@mui/material';
import { IFileManager } from '#/types/file';
import { useDoubleClick } from '#/lib/hooks/useDoubleClick';
import useBoolean from '#/lib/hooks/useBoolean';
import FileThumbnail from '#/components/shared/file-thumbnail';
import { fData } from '#/utils/formatNumber';
import { fDate, fTime } from '#/utils/formatTime';

type Props = {
  row: IFileManager;
  selected: boolean;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function FileTableRow({
  row,
  selected,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { name, size, type, modifiedAt } = row;

  const details = useBoolean();
  const theme = useTheme();

  const handleClick = useDoubleClick({
    click: () => {
      details.onTrue();
    },
    doubleClick: () => console.info('DOUBLE CLICK'),
  });

  return (
    <TableRow
      selected={selected}
      sx={{
        borderRadius: 2,
        [`&.${tableRowClasses.selected}, &:hover`]: {
          backgroundColor: 'background.paper',
          boxShadow: theme.customShadows.z20,
          transition: theme.transitions.create(
            ['background-color', 'box-shadow'],
            {
              duration: theme.transitions.duration.shortest,
            }
          ),
          '&:hover': {
            backgroundColor: 'background.paper',
            boxShadow: theme.customShadows.z20,
          },
        },
        [`& .${tableCellClasses.root}`]: {},
        ...(details.value && {
          [`& .${tableCellClasses.root}`]: {},
        }),
      }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selected}
          onDoubleClick={() => console.info('ON DOUBLE CLICK')}
          onClick={onSelectRow}
        />
      </TableCell>

      <TableCell onClick={handleClick}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <FileThumbnail file={type} sx={{ width: 36, height: 36 }} />

          <Typography
            noWrap
            variant="inherit"
            sx={{
              maxWidth: 360,
              cursor: 'pointer',
              ...(details.value && { fontWeight: 'fontWeightBold' }),
            }}
          >
            {name}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell onClick={handleClick} sx={{ whiteSpace: 'nowrap' }}>
        {fData(size)}
      </TableCell>

      <TableCell onClick={handleClick} sx={{ whiteSpace: 'nowrap' }}>
        {type}
      </TableCell>

      <TableCell onClick={handleClick} sx={{ whiteSpace: 'nowrap' }}>
        <ListItemText
          primary={fDate(modifiedAt)}
          secondary={fTime(modifiedAt)}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>
    </TableRow>
  );
}
