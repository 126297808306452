import { Box, Table, TableBody, TableContainer, useTheme } from '@mui/material';
import { TableProps } from '#/types/elements';
import { IFile } from '#/types/file';
import FileTableRow from './table-row';
import TableHeadCustom from '#/components/shared/table/table-head-custom';

type Props = {
  table: TableProps;
  notFound: boolean;
  dataFiltered: IFile[];
  onOpenConfirm: VoidFunction;
  onDeleteRow: (id: string) => void;
};

const TABLE_HEAD = [
  { id: '', width: 88 },

  { id: 'name', label: 'Name' },

  { id: 'size', label: 'Size', width: 120 },
  { id: 'type', label: 'Type', width: 120 },
  { id: 'modifiedAt', label: 'Modified', width: 140 },
];

export default function TableView({
  table,
  onDeleteRow,
  notFound,
  onOpenConfirm,
  dataFiltered,
}: Props) {
  const theme = useTheme();

  const { selected, onSelectRow } = table;

  return (
    <Box
      sx={{
        position: 'relative',
        m: theme.spacing(-2, -3, -3, -3),
      }}
    >
      <TableContainer
        sx={{
          p: theme.spacing(0, 3, 3, 3),
        }}
      />
      <Table
        size="small"
        sx={{
          minWidth: 960,
          borderCollapse: 'separate',
          borderSpacing: '0 16px',
        }}
      >
        <TableHeadCustom headLabel={TABLE_HEAD} />
        <TableBody>
          <FileTableRow
            row={dataFiltered[0]}
            selected={selected.includes(dataFiltered[0].id)}
            onSelectRow={() => onSelectRow(dataFiltered[0].id)}
            onDeleteRow={() => onDeleteRow(dataFiltered[0].id)}
          />
        </TableBody>
      </Table>
    </Box>
  );
}
