import { Box } from '@mui/material';
import { useRef } from 'react';
import GridFolderItem from './grid-folder-item';
import FileManagerPanel from '#/components/shared/file-manager/file-manager-panel';
import { TableProps } from '#/types/elements';
import { IFile } from '#/types/file';

type Props = {
  table: TableProps;
  dataFiltered: IFile[];
  onOpenConfirm: VoidFunction;
  onDeleteItem: (id: string) => void;
};

export default function GridView({
  table,
  dataFiltered,
  onOpenConfirm,
  onDeleteItem,
}: Props) {
  const {
    selected,
    onSelectRow: onSelectItem,
    onSelectAllRows: onSelectAllItems,
  } = table;

  const containerRef = useRef(null);

  return (
    <Box ref={containerRef}>
      <FileManagerPanel title="Folder" subTitle="4 folders" />
      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(4, 1fr)',
        }}
      >
        <GridFolderItem
          folder={dataFiltered[0]}
          onSelect={() => onSelectItem(dataFiltered[0].id)}
          onDelete={() => onDeleteItem(dataFiltered[0].id)}
          sx={{ maxWidth: 'auto' }}
        />
      </Box>
    </Box>
  );
}
